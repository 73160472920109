import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MARKETS } from '@clearscore/config.i18n';
import { Form } from 'react-final-form';
import Overlay from '@clearscore/rainbow.overlay';
import FormLayout from '@clearscore/rainbow.form-layout';
import { useLocation } from 'react-router-dom';

import ChangeEmailForm from '../change-email-form';
import HiddenEmailInput from '../hidden-email-input';

const CreateAccountForm = ({
    initialValues,
    isEmailValid,
    onSubmitProps,
    onSubmit,
    onSubmitEndpoint,
    onSubmitTransform,
    getKeysToRemove,
    isBlackboxStart,
    isSubmitting,
    layoutComponents,
    market,
}) => {
    const { search } = useLocation();
    const { setIsOpen } = Overlay.useContext();

    const handleShouldDisplayOverlay = (values) => {
        if (isEmailValid) {
            onSubmit({
                ...values,
                ...onSubmitProps,
                onSubmitTransform,
                onSubmitEndpoint,
                search,
                getKeysToRemove,
                isBlackboxStart,
            });
        } else {
            setIsOpen(true);
        }
    };

    return (
        <Fragment>
            <Overlay.Content>
                <ChangeEmailForm market={market} />
            </Overlay.Content>
            <Form
                initialValues={initialValues}
                onSubmit={handleShouldDisplayOverlay}
                keepDirtyOnReinitialize
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
            >
                {({ handleSubmit, ...props }) => (
                    <FormLayout>
                        <form onSubmit={handleSubmit} name="create-account-form" id="createAccountForm">
                            {layoutComponents.map((FieldComponent) => {
                                const comp = FieldComponent({
                                    ...props,
                                    submitting: isSubmitting,
                                });

                                // This is for chrome / other browsers automatically saving username / password
                                // See comment in HiddenEmailInput component
                                if (comp.key === 'password') {
                                    return (
                                        <Fragment key={comp.key}>
                                            <HiddenEmailInput />
                                            {comp}
                                        </Fragment>
                                    );
                                }

                                return comp;
                            })}
                        </form>
                    </FormLayout>
                )}
            </Form>
        </Fragment>
    );
};

CreateAccountForm.propTypes = {
    onSubmitProps: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onSubmitEndpoint: PropTypes.string.isRequired,
    onSubmitTransform: PropTypes.object,
    initialValues: PropTypes.object,
    isSubmitting: PropTypes.bool.isRequired,
    isEmailValid: PropTypes.bool,
    getKeysToRemove: PropTypes.func,
    isBlackboxStart: PropTypes.bool,
    layoutComponents: PropTypes.arrayOf(PropTypes.func).isRequired,
    market: PropTypes.oneOf(MARKETS).isRequired,
};

CreateAccountForm.defaultProps = {
    onSubmitProps: {},
    initialValues: null,
    isEmailValid: true,
    getKeysToRemove: () => [],
    isBlackboxStart: false,
    onSubmitTransform: {},
};

export default CreateAccountForm;

import { createElement } from 'react';
import interpolateWithComponents from '@clearscore/helpers.interpolate-with-components';
import { GB, ZA, AU, CA, GLOBAL } from '@clearscore/config.i18n';
import normalizePinCode from '@clearscore/normalize.pincode';
import { formatWithoutPrefix } from '@clearscore/normalize.phone';
import isStrongPasswordValidation from '@clearscore/validation.is-strong-password';
import hasLowerCase from '@clearscore/validation.has-lower-case';
import hasUpperCase from '@clearscore/validation.has-upper-case';
import hasNumber from '@clearscore/validation.has-number';
import minLength from '@clearscore/validation.min-length';
import maxLength from '@clearscore/validation.max-length';
import Text from '@clearscore/rainbow.text';
import i18next from 'i18next';

import validationFactory from './validation-config';
import fieldPropsFactory from './field-props-config';
import {
    DATE_OF_BIRTH,
    DATE_OF_BIRTH_CA,
    DOC_TYPE_NATIONAL_ID,
    DOCUMENT_ID,
    FEMALE,
    FIRST_NAME,
    MIDDLE_NAME,
    FULLNAME,
    GENDER,
    ID_TYPE,
    LAST_NAME,
    LEGAL,
    MALE,
    PASSWORD,
    PHONE_NUMBER,
    PIN_CODE,
    PRIVACY,
    SUBMIT,
    TERMS,
    SOCIAL_INSURANCE_NUMBER,
    TERMS_AND_PRIVACY,
    AU_CURRENT_ADDRESS,
    AU_PREVIOUS_ADDRESS,
    ADDRESS_LENGTH_INSUFFICIENT,
    DOC_TYPE_MEDICARE,
    DOC_TYPE_DRIVING_LICENCE_STATE,
    DOC_TYPE_MEDICARE_CARD_TYPE,
    DOC_TYPE_MEDICARE_POSITION,
    DOC_TYPE_MEDICARE_EXPIRY,
    DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS,
    DOC_TYPE_PASSPORT_COUNTRY,
    DOC_TYPE_DRIVING_LICENCE_AU,
    DOC_TYPE_PASSPORT_AU,
    DOC_TYPE_PASSPORT_AU_FOREIGN,
    DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER,
} from './field-names';
import { PAGE_NAMESPACE } from '../constants';

const getMarketLink = (market, pathname) => {
    const isGlobalPath = market === GB || market === GLOBAL;
    if (isGlobalPath) return `https://www.clearscore.com/${pathname}`;

    return `https://www.clearscore.com/${market}/${pathname}`;
};
export default ({ details, locale, market, currentSuggestions, previousSuggestions }) => {
    const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
    const validate = validationFactory(market, locale, currentSuggestions, previousSuggestions);
    const getFieldProps = fieldPropsFactory(details, locale);
    const fieldProps = {
        fullName: getFieldProps(FULLNAME, {
            validate: validate(FULLNAME)(),
            autocomplete: 'name',
        }),
        firstName: getFieldProps(FIRST_NAME, {
            validate: validate(FIRST_NAME)(),
            autocomplete: 'first-name',
        }),
        lastName: getFieldProps(LAST_NAME, {
            validate: validate(LAST_NAME)(),
            autocomplete: 'last-name',
        }),
        dateOfBirth: getFieldProps(DATE_OF_BIRTH, {
            validate: validate(DATE_OF_BIRTH)(),
        }),
        legal: {
            introMessages: Object.values(t(`${LEGAL}.introMessages`))
                .map((message, idx) => [
                    // Add some line breaks between sections for readability
                    ...(idx > 0
                        ? [
                              createElement('br', { key: `legal-br-${idx}-1` }),
                              createElement('br', { key: `legal-br-${idx}-2` }),
                          ]
                        : [null]),
                    ...interpolateWithComponents(message, [
                        {
                            Component: Text.Link,
                            props: {
                                key: 'terms-link',
                                href: getMarketLink(market, 'terms'),
                                isNewTab: true,
                            },
                        },
                        {
                            Component: Text.Link,
                            props: {
                                key: 'privacy-policy-link',
                                href: getMarketLink(market, 'privacy-policy'),
                                isNewTab: true,
                            },
                        },
                    ]),
                ])
                .flat(),
        },
        pinCode: getFieldProps(PIN_CODE, {
            maxLength: 6,
            type: 'text',
            autocomplete: 'postal-code',
            parse: normalizePinCode,
            validate: validate(PIN_CODE)(),
        }),
        phoneNumber: getFieldProps(PHONE_NUMBER, {
            autocomplete: 'tel-national',
            type: 'tel',
            parse: formatWithoutPrefix,
            maxLength: 10,
            validate: validate(PHONE_NUMBER)(),
        }),
        password: getFieldProps(PASSWORD, {
            validate: validate(PASSWORD)({ isRelaxed: false }),
            withRequirements: true,
            autocomplete: 'new-password',
            requirements: [
                {
                    id: 'upper-case-lower-case',
                    text: t(`${PASSWORD}.validation.upperCaseLowerCase`),
                    validator: (value) => hasLowerCase(value) && hasUpperCase(value),
                },
                {
                    id: 'at-least-one-number',
                    text: t(`${PASSWORD}.validation.atLeastOneNumber`),
                    validator: hasNumber,
                },
                {
                    id: 'between-eight-to-fifty',
                    text: t(`${PASSWORD}.validation.eightAndFiftyChar`),
                    validator: (value) => minLength(8)(value) && maxLength(50)(value),
                },
            ],
        }),
        relaxedPassword: getFieldProps(PASSWORD, {
            validate: validate(PASSWORD)({ isRelaxed: true }),
            withRelaxedRequirements: true,
            autocomplete: 'new-password',
            requirements: [
                {
                    id: 'eight-characters',
                    text: t(`${PASSWORD}.validation.eightOrMoreChar`),
                    validator: isStrongPasswordValidation(8, 50, {
                        relaxedRequirements: true,
                    }),
                },
            ],
        }),
        terms: getFieldProps(TERMS, {
            label: interpolateWithComponents(t(`${TERMS}.label`), [
                {
                    Component: Text.Link,
                    props: {
                        key: 'terms-link',
                        href: getMarketLink(market, 'terms'),
                        isNewTab: true,
                        isLink: true,
                    },
                },
            ]),
            validate: validate(TERMS)(),
        }),
        privacy: getFieldProps(PRIVACY, {
            label: interpolateWithComponents(t(`${PRIVACY}.label`), [
                {
                    Component: Text.Link,
                    props: {
                        key: 'privacy-policy-link',
                        href: getMarketLink(market, 'privacy-policy'),
                        isNewTab: true,
                        isLink: true,
                    },
                },
            ]),
            validate: validate(PRIVACY)(),
        }),
        termsAndPrivacy: getFieldProps(TERMS_AND_PRIVACY, {
            label: interpolateWithComponents(t(`${TERMS_AND_PRIVACY}.label`), [
                {
                    Component: Text.Link,
                    props: {
                        key: 'terms-link',
                        href: getMarketLink(market, 'terms'),
                        isNewTab: true,
                        isLink: true,
                    },
                },
                {
                    Component: Text.Link,
                    props: {
                        key: 'privacy-policy-link',
                        href: getMarketLink(market, 'privacy-policy'),
                        isNewTab: true,
                        isLink: true,
                    },
                },
            ]),
            validate: validate(TERMS_AND_PRIVACY)(),
        }),
        submit: getFieldProps(SUBMIT),
    };

    switch (market) {
        case ZA:
            return {
                ...fieldProps,
                binaryGender: getFieldProps(GENDER, { options: [MALE, FEMALE] }),
                firstNames: getFieldProps(FIRST_NAME, {
                    validate: validate(FIRST_NAME)({ options: { spaceAllowed: true } }),
                    autocomplete: 'first-name',
                }),
                [DOC_TYPE_NATIONAL_ID]: getFieldProps(DOC_TYPE_NATIONAL_ID, {
                    validate: validate(DOC_TYPE_NATIONAL_ID)(),
                    name: DOCUMENT_ID,
                    parse: normalizePinCode,
                }),
            };
        case AU:
            return {
                ...fieldProps,
                [FULLNAME]: getFieldProps(FULLNAME, {
                    description: interpolateWithComponents(t(`${FULLNAME}.description`), [
                        {
                            Component: Text,
                            props: {
                                weight: Text.weights.BOLD,
                                tag: Text.tags.SPAN,
                                key: FULLNAME,
                                type: Text.types.MINI,
                            },
                        },
                    ]),
                }),
                [FIRST_NAME]: getFieldProps(FIRST_NAME, {
                    validate: validate(FIRST_NAME)({
                        maxLength: 40,
                        options: { spaceAllowed: true, allowMultipleSpecialChars: true },
                        allowAccentedCharacters: false,
                    }),
                }),
                [MIDDLE_NAME]: getFieldProps(MIDDLE_NAME, {
                    validate: validate(MIDDLE_NAME)({ maxLength: 40, allowAccentedCharacters: false }),
                }),
                [LAST_NAME]: getFieldProps(LAST_NAME, {
                    validate: validate(LAST_NAME)({
                        maxLength: 60,
                        allowAccentedCharacters: false,
                        allowMultipleSpecialChars: true,
                    }),
                }),
                [ID_TYPE]: getFieldProps(ID_TYPE, {
                    name: ID_TYPE,
                }),
                [DOC_TYPE_DRIVING_LICENCE_AU]: getFieldProps(DOC_TYPE_DRIVING_LICENCE_AU, {
                    name: DOC_TYPE_DRIVING_LICENCE_AU,
                    validate: validate(DOC_TYPE_DRIVING_LICENCE_AU)(),
                }),
                [DOC_TYPE_DRIVING_LICENCE_STATE]: getFieldProps(DOC_TYPE_DRIVING_LICENCE_STATE, {
                    name: DOC_TYPE_DRIVING_LICENCE_STATE,
                    validate: validate(DOC_TYPE_DRIVING_LICENCE_STATE)(),
                }),
                [DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER]: getFieldProps(DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER, {
                    name: DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER,
                    description: interpolateWithComponents(t(`${DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER}.description`), [
                        {
                            Component: Text,
                            props: {
                                weight: Text.weights.BOLD,
                                tag: Text.tags.SPAN,
                                type: Text.types.MINI,
                            },
                            key: DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER,
                        },
                    ]),
                    validate: validate(DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER)(),
                }),

                [DOC_TYPE_MEDICARE]: getFieldProps(DOC_TYPE_MEDICARE, {
                    name: DOC_TYPE_MEDICARE,
                    validate: validate(DOC_TYPE_MEDICARE)(),
                    autocomplete: 'cc-csc', // hack to get autocomplete to be "off"
                }),
                [DOC_TYPE_MEDICARE_CARD_TYPE]: getFieldProps(DOC_TYPE_MEDICARE_CARD_TYPE, {
                    name: DOC_TYPE_MEDICARE_CARD_TYPE,
                }),
                [DOC_TYPE_MEDICARE_POSITION]: getFieldProps(DOC_TYPE_MEDICARE_POSITION, {
                    name: DOC_TYPE_MEDICARE_POSITION,
                    validate: validate(DOC_TYPE_MEDICARE_POSITION)(),
                    labelDescription: interpolateWithComponents(t(`${DOC_TYPE_MEDICARE_POSITION}.labelDescription`), [
                        {
                            Component: Text,
                            props: {
                                weight: Text.weights.BOLD,
                                tag: Text.tags.SPAN,
                                type: Text.types.MINI,
                            },
                            key: DOC_TYPE_MEDICARE_POSITION,
                        },
                    ]),
                }),
                [DOC_TYPE_MEDICARE_EXPIRY]: getFieldProps(DOC_TYPE_MEDICARE_EXPIRY, {
                    name: DOC_TYPE_MEDICARE_EXPIRY,
                    validate: validate(DOC_TYPE_MEDICARE_EXPIRY)(),
                }),
                [DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS]: getFieldProps(DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS, {
                    name: DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS,
                    validate: validate(DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS)(),
                }),
                [DOC_TYPE_PASSPORT_AU]: getFieldProps(DOC_TYPE_PASSPORT_AU, {
                    validate: validate(DOC_TYPE_PASSPORT_AU)(),
                    name: DOC_TYPE_PASSPORT_AU,
                }),
                [DOC_TYPE_PASSPORT_AU_FOREIGN]: getFieldProps(DOC_TYPE_PASSPORT_AU_FOREIGN, {
                    validate: validate(DOC_TYPE_PASSPORT_AU_FOREIGN)({ maxLength: 14 }),
                    name: DOC_TYPE_PASSPORT_AU_FOREIGN,
                }),
                [DOC_TYPE_PASSPORT_COUNTRY]: getFieldProps(DOC_TYPE_PASSPORT_COUNTRY, {
                    validate: validate(DOC_TYPE_PASSPORT_COUNTRY)(),
                    name: DOC_TYPE_PASSPORT_COUNTRY,
                }),
                [AU_CURRENT_ADDRESS]: getFieldProps(AU_CURRENT_ADDRESS, {
                    validate: validate(AU_CURRENT_ADDRESS)(currentSuggestions),
                }),
                [AU_PREVIOUS_ADDRESS]: getFieldProps(AU_PREVIOUS_ADDRESS, {
                    validate: validate(AU_PREVIOUS_ADDRESS)(previousSuggestions),
                }),
                [ADDRESS_LENGTH_INSUFFICIENT]: getFieldProps(ADDRESS_LENGTH_INSUFFICIENT),
            };
        case CA:
            return {
                ...fieldProps,
                [FULLNAME]: getFieldProps(FULLNAME, {
                    description: interpolateWithComponents(t(`${FULLNAME}.description`), [
                        {
                            Component: Text,
                            props: {
                                weight: Text.weights.BOLD,
                                tag: Text.tags.SPAN,
                                key: FULLNAME,
                                type: Text.types.MINI,
                            },
                        },
                    ]),
                }),
                [FIRST_NAME]: getFieldProps(FIRST_NAME, {
                    validate: validate(FIRST_NAME)({
                        maxLength: 40,
                        options: { spaceAllowed: true, allowMultipleSpecialChars: true },
                        allowAccentedCharacters: false,
                    }),
                }),
                [MIDDLE_NAME]: getFieldProps(MIDDLE_NAME, {
                    validate: validate(MIDDLE_NAME)({ maxLength: 40, allowAccentedCharacters: false }),
                }),
                [LAST_NAME]: getFieldProps(LAST_NAME, {
                    validate: validate(LAST_NAME)({
                        maxLength: 60,
                        allowAccentedCharacters: false,
                        allowMultipleSpecialChars: true,
                    }),
                }),
                dateOfBirth: getFieldProps(DATE_OF_BIRTH, {
                    validate: validate(DATE_OF_BIRTH_CA)(),
                }),
                [SOCIAL_INSURANCE_NUMBER]: getFieldProps(SOCIAL_INSURANCE_NUMBER, {
                    validate: validate(SOCIAL_INSURANCE_NUMBER)(),
                    name: DOCUMENT_ID,
                    parse: normalizePinCode,
                }),
            };
        case GB:
        default:
            return {
                ...fieldProps,
            };
    }
};

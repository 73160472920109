import React from 'react';
import { PropTypes } from 'prop-types';
import Autosuggest from '@clearscore/rainbow.autosuggest';
import { useDispatch, useSelector } from 'react-redux';
import { actions as registrationActions, selectors as registrationSelectors } from '@clearscore/redux.registration';

import marketConfig from './lib/market-config';
import styles from './address-autosuggest.module.css';

const AddressAutosuggest = ({ name, options, requiredMessage, market, ...rest }) => {
    const { suggestIndex } = marketConfig({ market, name });
    const dispatch = useDispatch();
    const action = (searchTerm) =>
        dispatch(registrationActions.fetchMultipleAddresses({ market, searchTerm, index: suggestIndex }));
    const { isPending } = useSelector(
        (state) => registrationSelectors.getPredicate(state, suggestIndex).fetchMultipleAddress,
    );

    return (
        <div className={styles.spacing}>
            <Autosuggest
                {...rest}
                isLightBg
                name={name}
                suggestions={options.map((suggestion) => suggestion.formattedAddress)}
                action={action}
                isPending={isPending}
                debounceTime={300}
                requiredMessage={requiredMessage}
            />
        </div>
    );
};

AddressAutosuggest.themes = Autosuggest.Theme;

AddressAutosuggest.propTypes = {
    market: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            addressId: PropTypes.string,
            formattedAddress: PropTypes.string,
        }),
    ).isRequired,
    requiredMessage: PropTypes.string.isRequired,
};

export default AddressAutosuggest;

import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '@clearscore/rainbow.text-input';
import FormLayout from '@clearscore/rainbow.form-layout';

import FieldComponent from './field';
import SubmitButtonImport from './submit-button';
import SegmentedGroupImport from './segmented-group';
import CheckboxInputImport from './checkbox-input';
import LegalImport from './legal';
import PasswordInput from './password-input';
import DateInputComponent from './date-input';
import FormLayoutGroupComponent from './form-layout-group';
import DropdownComponent from './dropdown';
import AddressAutosuggestComponent from './address-autosuggest';

export const Text = ({ key, ...props }) => (
    <FormLayout.Item key={key}>
        <FieldComponent Component={TextInput} type="text" {...props} />
    </FormLayout.Item>
);

Text.propTypes = {
    key: PropTypes.string.isRequired,
};

export const Tel = ({ key, ...props }) => (
    <FormLayout.Item key={key}>
        <FieldComponent Component={TextInput} type="tel" {...props} />
    </FormLayout.Item>
);

Tel.propTypes = {
    key: PropTypes.string.isRequired,
};

export const DateInput = ({ key, ...props }) => (
    <FormLayout.Item key={key}>
        <DateInputComponent {...props} />
    </FormLayout.Item>
);

DateInput.propTypes = {
    key: PropTypes.string.isRequired,
};

export const Password = ({ key, ...props }) => (
    <FormLayout.Item key={key}>
        <FieldComponent Component={PasswordInput} type="password" {...props} />
    </FormLayout.Item>
);

Password.propTypes = {
    key: PropTypes.string.isRequired,
};

export const SubmitButton = ({ key, ...props }) => (
    <FormLayout.Item key={key}>
        <SubmitButtonImport {...props} />
    </FormLayout.Item>
);

SubmitButton.propTypes = {
    key: PropTypes.string.isRequired,
};

export const Legal = ({ key, ...props }) => (
    <FormLayout.Item key={key}>
        <LegalImport {...props} />
    </FormLayout.Item>
);

Legal.propTypes = {
    key: PropTypes.string.isRequired,
};

export const SegmentedGroup = ({ key, hasFormLayout, ...props }) =>
    hasFormLayout ? (
        <FormLayout.Item key={key}>
            <SegmentedGroupImport {...props} />
        </FormLayout.Item>
    ) : (
        <div key={key}>
            <SegmentedGroupImport {...props} />
        </div>
    );

SegmentedGroup.propTypes = {
    key: PropTypes.string.isRequired,
    hasFormLayout: PropTypes.bool.isRequired,
};

export const Checkbox = ({ key, hasFormLayout, ...props }) =>
    hasFormLayout ? (
        <FormLayout.Item key={key}>
            <CheckboxInputImport {...props} />
        </FormLayout.Item>
    ) : (
        <div key={key}>
            <CheckboxInputImport {...props} />
        </div>
    );

Checkbox.propTypes = {
    key: PropTypes.string.isRequired,
    hasFormLayout: PropTypes.bool.isRequired,
};

export const FormLayoutGroup = (props) => <FormLayoutGroupComponent {...props} />;

export const Dropdown = ({ key, ...props }) => (
    <FormLayout.Item key={key}>
        <DropdownComponent {...props} />
    </FormLayout.Item>
);

Dropdown.propTypes = {
    key: PropTypes.string.isRequired,
};

export const AddressAutosuggest = ({ key, ...props }) => (
    <FormLayout.Item key={key}>
        <FieldComponent Component={AddressAutosuggestComponent} {...props} />
    </FormLayout.Item>
);

AddressAutosuggest.propTypes = {
    key: PropTypes.string.isRequired,
};

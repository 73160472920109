import { flatMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { types as prospectTypes } from '@clearscore/redux.prospect';

// we need to submit the create account form when a prospect with a valid email has been successfully created
// change email modal will redirect to login if email has already been registered
const createAccountEpic = (action$) =>
    action$.pipe(
        ofType(prospectTypes.PROSPECT_CREATE_SUCCESS),
        flatMap(({ payload }) => {
            const { prospectHasAccount, allowedUser, email } = payload || {};
            const userHasAlreadyRegistered = prospectHasAccount || !allowedUser;
            const form = document.getElementById('createAccountForm');

            if (userHasAlreadyRegistered) {
                const location = {
                    pathname: `/login`,
                    state: { registeredEmailValue: email },
                };
                return of(push(location));
            } else if (form) {
                of(form.dispatchEvent(new window.Event('submit', { cancelable: true, bubbles: true })));
            }

            return EMPTY;
        }),
    );

export default createAccountEpic;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import SegmentedControl from '@clearscore/rainbow.segmented-control';
import Label from '@clearscore/rainbow.label';

const SegmentedGroup = ({
    options,
    label,
    isGrouped,
    name,
    validate,
    initialActiveFieldName,
    dockedBottom,
    description,
    forId,
    isHidden,
    onMount,
}) => {
    useEffect(() => {
        if (!isHidden && onMount) {
            onMount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHidden]);

    if (isHidden) return null;

    return (
        <div data-qa="segmented-group" key={label}>
            {label ? (
                <Label htmlFor={name} description={description} forId={forId}>
                    {label}
                </Label>
            ) : null}
            <Field name={name} validate={validate}>
                {({ input: { onChange: onFieldChange } }) => (
                    <SegmentedControl
                        handleChange={(activeIndex) => onFieldChange(options[activeIndex].value)}
                        isGrouped={isGrouped}
                        size={SegmentedControl.Size.MEDIUM}
                        dockedBottom={dockedBottom}
                    >
                        {options.map(({ text, value }) => (
                            <SegmentedControl.Option
                                key={value}
                                dataId={`segmented-${value}-button`}
                                isInitialActive={value === initialActiveFieldName}
                            >
                                {text}
                            </SegmentedControl.Option>
                        ))}
                    </SegmentedControl>
                )}
            </Field>
        </div>
    );
};

SegmentedGroup.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.string,
        }),
    ).isRequired,
    label: PropTypes.string,
    isGrouped: PropTypes.bool,
    name: PropTypes.string.isRequired,
    initialActiveFieldName: PropTypes.string,
    validate: PropTypes.func,
    dockedBottom: PropTypes.bool,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    forId: PropTypes.string.isRequired,
    isHidden: PropTypes.bool,
    onMount: PropTypes.func,
};

SegmentedGroup.defaultProps = {
    label: null,
    initialActiveFieldName: undefined,
    dockedBottom: false,
    description: null,
    isHidden: false,
    validate: () => undefined,
    isGrouped: false,
    onMount: null,
};

export default SegmentedGroup;

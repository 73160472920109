import { EN_GB, EN_AU, EN_ZA, EN_CA } from '@clearscore/config.i18n';
import i18next from 'i18next';

import copyEnGb from './copy/pages/registration-create-account/en-gb/copy.json';
import copyEnAu from './copy/pages/registration-create-account/en-au/copy.json';
import copyEnZa from './copy/pages/registration-create-account/en-za/copy.json';
import copyEnCa from './copy/pages/registration-create-account/en-ca/copy.json';
import CreateAccount from './create-account.container';
import { PAGE_NAMESPACE } from './lib/constants';

i18next.addResourceBundle(EN_GB, PAGE_NAMESPACE, copyEnGb);
i18next.addResourceBundle(EN_AU, PAGE_NAMESPACE, copyEnAu);
i18next.addResourceBundle(EN_ZA, PAGE_NAMESPACE, copyEnZa);
i18next.addResourceBundle(EN_CA, PAGE_NAMESPACE, copyEnCa);

export const Component = CreateAccount;

export default Component;

import i18next from 'i18next';

import { PAGE_NAMESPACE } from '../constants';

export default (state, locale) => {
    const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
    return (fieldName, { options = [], validate = null, ...overrideComponentProps } = {}) => {
        const { label, description, placeholder } = t(fieldName);

        const value = state[fieldName];
        return {
            options: options.map((key) => {
                const keyLabel = t(`${key}.label`);
                return {
                    key,
                    value: key,
                    text: keyLabel,
                    children: keyLabel,
                    label: keyLabel,
                };
            }),
            description,
            name: fieldName,
            forId: fieldName,
            key: fieldName,
            validate,
            label,
            placeholder,
            value,
            ...overrideComponentProps,
        };
    };
};

import createAction from '@clearscore/helpers.create-action';
import createRequestPayload from '@clearscore/helpers.create-request-payload';
import { types } from '@clearscore/redux.registration';
import getEnv from '@clearscore/helpers.envs';
import getDevice from '@clearscore/helpers.device';
import fingerprint from '@clearscore/helpers.device-fingerprinting';

/* eslint-disable import/prefer-default-export */
export const createAccount = ({ accountData, endpoint }, market) =>
    createAction(
        types.REGISTRATION_CREATE_ACCOUNT,
        createRequestPayload(
            'international',
            endpoint,
            'post',
            {
                responseType: getEnv('OAUTH_ENABLED') ? 'llt' : 'slt',
                deviceId: fingerprint.hash,
                deviceName: getDevice.DEVICE_NAME,
                clientId: getEnv('OAUTH_CLIENT_ID'),
                refreshTokenTTL: getEnv('OAUTH_REFRESH_TLL'),
                ...accountData,
            },
            {
                market,
            },
        ),
    );

import React from 'react';
import { useSelector } from 'react-redux';
import { selectors as prospectSelectors } from '@clearscore/redux.prospect';

/**
 * Hidden input used to ensure "email" is used as a login field when saving usename / password
 * in browser keychain. This should appear directly above the "password" input
 *
 * If this is not included, chrome will use the field directly above "password" as the usename
 * when saving credentials, eg. "date of birth" for GB reg
 */
const HiddenEmailInput = () => {
    const { email } = useSelector(prospectSelectors.getSimple) ?? {};

    if (!email) return null;

    return (
        <div data-qa="hidden-prospect-email-input" style={{ display: 'none' }}>
            <input readOnly type="username" id="username" autoComplete="username" value={email} />
        </div>
    );
};

export default HiddenEmailInput;

/**
 * Get a function from the window iovation object
 * @param {string} funcName name of function to get
 * @return {Function|undefined}
 */
export function getIovationFunction(funcName) {
    return window[window.io_global_object_name || 'IGLOO']?.[funcName];
}

/**
 * Get the iovation blackbox string, or blank string if iovation is not loaded
 * @return {string} iovation black box string
 */
export function getBlackbox() {
    const getBlackboxFunc = getIovationFunction('getBlackbox');
    return typeof getBlackboxFunc === 'function' ? getBlackboxFunc().blackbox : '';
}

import { isDateValidation } from '@clearscore/validation.is-date';
import { isDateFormatValidation } from '@clearscore/validation.is-date-format';
import isNationalIdNumberValidation from '@clearscore/validation.is-national-id-number';
import { isRequiredValidation } from '@clearscore/validation.is-required';
import { isFirstNameValidation } from '@clearscore/validation.is-first-name';
import { isLastNameValidation } from '@clearscore/validation.is-last-name';
import { isPinCodeValidation } from '@clearscore/validation.is-pincode';
import { isPhoneNumberValidation } from '@clearscore/validation.is-phone-number';
import { hasExpiredValidation } from '@clearscore/validation.has-expired';
import { minAgeValidation } from '@clearscore/validation.min-age';
import { isStrongPasswordValidation } from '@clearscore/validation.is-strong-password';
import { isNameValidation } from '@clearscore/validation.is-name';
import { isAuPassportValidation } from '@clearscore/validation.is-passport-number';
import { isPermanentAccountNumberValidation } from '@clearscore/validation.is-permanent-account-number';
import { minLengthValidation } from '@clearscore/validation.min-length';
import { maxLengthValidation } from '@clearscore/validation.max-length';
import { hasSuggestionValidation } from '@clearscore/validation.has-suggestion';
import hasAccentedCharacters from '@clearscore/validation.has-accented-characters';
import {
    isForeignPassportValidation,
    isMiddleNameValidation,
    isMedicareValidation,
    isDrivingLicence,
    isDrivingLicenceCardNumber,
} from '@shared/validation-au';
import i18next from 'i18next';

import {
    FULLNAME,
    DATE_OF_BIRTH,
    DATE_OF_BIRTH_CA,
    PIN_CODE,
    PHONE_NUMBER,
    PASSWORD,
    FIRST_NAME,
    DOC_TYPE_NATIONAL_ID,
    LAST_NAME,
    SOCIAL_INSURANCE_NUMBER,
    DOC_TYPE_PAN,
    DOC_TYPE_PASSPORT,
    DOC_TYPE_DRIVING_LICENCE,
    DOC_TYPE_DRIVING_LICENCE_STATE,
    DOC_TYPE_MEDICARE_POSITION,
    DOC_TYPE_MEDICARE,
    DOC_TYPE_MEDICARE_EXPIRY,
    DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS,
    DOC_TYPE_PASSPORT_COUNTRY,
    DOC_TYPE_DRIVING_LICENCE_AU,
    DOC_TYPE_PASSPORT_AU_FOREIGN,
    DOC_TYPE_PASSPORT_AU,
    MIDDLE_NAME,
    AU_CURRENT_ADDRESS,
    AU_PREVIOUS_ADDRESS,
    DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER,
} from './field-names';
import { isCADate, minAgeCAValidation, isSIN } from '../date-helpers/index';
import { PAGE_NAMESPACE } from '../constants';

const accentedCharactersValidator = (errorMessage) => (value) => hasAccentedCharacters(value) && errorMessage;

const composeValidators =
    (...validators) =>
    (value, values) =>
        validators
            .filter((validator) => validator)
            .reduce((error, validator) => error || validator(value, values), undefined);

export default (market, locale, currentSuggestions, previousSuggestions) => (fieldName) => {
    const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
    const { validation = {} } = t(fieldName) || {};
    const requiredValidation = isRequiredValidation(validation.mandatory);

    const addressValidation = (suggestions) =>
        composeValidators(
            requiredValidation,
            hasSuggestionValidation(
                suggestions.map((suggestion) => suggestion.formattedAddress),
                validation.selectSuggestion,
            ),
        );
    switch (fieldName) {
        case FULLNAME:
            return ({ minLength = 2, maxLength = 50 } = {}) =>
                composeValidators(
                    requiredValidation,
                    isNameValidation(validation.firstName, validation.lastName)(minLength, maxLength),
                );
        case FIRST_NAME:
            return ({ minLength = 2, maxLength = 50, options = {}, allowAccentedCharacters = true } = {}) =>
                composeValidators(
                    requiredValidation,
                    isFirstNameValidation(validation.requirements)(minLength, maxLength, options),
                    !allowAccentedCharacters && accentedCharactersValidator(validation.accentedCharacters),
                );
        case MIDDLE_NAME:
            return ({ maxLength = 50, allowAccentedCharacters = true } = {}) =>
                composeValidators(
                    isMiddleNameValidation(maxLength, validation.requirements),
                    !allowAccentedCharacters && accentedCharactersValidator(validation.accentedCharacters),
                );
        case LAST_NAME:
            return ({
                minLength = 2,
                maxLength = 50,
                allowAccentedCharacters = true,
                allowMultipleSpecialChars = false,
            } = {}) =>
                composeValidators(
                    requiredValidation,
                    isLastNameValidation(validation.requirements)(minLength, maxLength, { allowMultipleSpecialChars }),
                    !allowAccentedCharacters && accentedCharactersValidator(validation.accentedCharacters),
                );
        case DATE_OF_BIRTH:
            return ({ minAge = 18 } = {}) =>
                composeValidators(
                    requiredValidation,
                    isDateValidation(undefined, validation.date),
                    minAgeValidation(validation.age)(minAge),
                );
        case DATE_OF_BIRTH_CA:
            return ({ minAge = 18 } = {}) =>
                composeValidators(
                    requiredValidation,
                    isCADate(validation.date),
                    minAgeCAValidation(validation.age)(minAge),
                );
        case PASSWORD:
            return ({ minLength = 8, maxLength = 50, isRelaxed = false } = {}) =>
                composeValidators(
                    requiredValidation,
                    isStrongPasswordValidation(validation.requirements, { relaxedRequirements: isRelaxed })(
                        minLength,
                        maxLength,
                    ),
                );
        case PIN_CODE:
            return () => composeValidators(requiredValidation, isPinCodeValidation(validation.requirements));
        case DOC_TYPE_PAN:
            return () =>
                composeValidators(requiredValidation, isPermanentAccountNumberValidation(validation.requirements));
        case DOC_TYPE_PASSPORT:
            return ({ minLength = 0, maxLength = 50 } = {}) =>
                composeValidators(
                    requiredValidation,
                    minLengthValidation(validation.short)(minLength),
                    maxLengthValidation(validation.long)(maxLength),
                );

        case DOC_TYPE_NATIONAL_ID:
            return () =>
                composeValidators(
                    requiredValidation,
                    isNationalIdNumberValidation({
                        errorMessages: validation,
                    }),
                );

        case DOC_TYPE_DRIVING_LICENCE:
            return () => composeValidators(requiredValidation);

        case DOC_TYPE_DRIVING_LICENCE_AU:
            return () =>
                composeValidators(requiredValidation, (value, values) =>
                    isDrivingLicence(
                        {
                            requirements: validation.requirements,
                            spaces: validation.spaces,
                        },
                        values[DOC_TYPE_DRIVING_LICENCE_STATE],
                    )(value),
                );
        case DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER:
            return () =>
                composeValidators(requiredValidation, (value, values) =>
                    isDrivingLicenceCardNumber(
                        { requirements: validation.requirements },
                        values[DOC_TYPE_DRIVING_LICENCE_STATE],
                    )(value),
                );
        case DOC_TYPE_PASSPORT_AU_FOREIGN:
            return ({ maxLength = 50 } = {}) =>
                composeValidators(
                    requiredValidation,
                    maxLengthValidation(validation.long)(maxLength),
                    isForeignPassportValidation(validation.passport),
                );
        case DOC_TYPE_PASSPORT_AU:
            return () => composeValidators(requiredValidation, isAuPassportValidation(validation.passport));

        case DOC_TYPE_PASSPORT_COUNTRY:
            return () => composeValidators(requiredValidation);

        case DOC_TYPE_DRIVING_LICENCE_STATE:
            return () => composeValidators(requiredValidation);

        case DOC_TYPE_MEDICARE:
            return () => composeValidators(requiredValidation, isMedicareValidation(validation.requirements));

        case DOC_TYPE_MEDICARE_POSITION:
            return () => composeValidators(requiredValidation);

        case DOC_TYPE_MEDICARE_EXPIRY:
            return ({ dateFormat = 'MM-YYYY' } = {}) =>
                composeValidators(
                    requiredValidation,
                    isDateFormatValidation(validation.date)(dateFormat),
                    hasExpiredValidation(validation.expired),
                );

        case DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS:
            return ({ dateFormat = 'DD-MM-YYYY' } = {}) =>
                composeValidators(
                    requiredValidation,
                    isDateFormatValidation(validation.date)(dateFormat),
                    hasExpiredValidation(validation.expired),
                );

        case PHONE_NUMBER:
            return () =>
                composeValidators(requiredValidation, isPhoneNumberValidation(market, validation.invalidFormat));

        case AU_CURRENT_ADDRESS:
            return () => addressValidation(currentSuggestions);

        case AU_PREVIOUS_ADDRESS:
            return () => addressValidation(previousSuggestions);
        case SOCIAL_INSURANCE_NUMBER:
            return () => composeValidators(isSIN(validation.length));
        default:
            return () => composeValidators(requiredValidation);
    }
};

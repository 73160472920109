import { AUSTRALIAN_STATES } from '@clearscore/constants.market-details';
import isEmpty from '@clearscore/validation.is-empty';
import isMultiSpecialCharName from '@clearscore/validation.is-multi-special-char-name';
import { isNumberValidation } from '@clearscore/validation.is-number';
import maxLength, { maxLengthValidation } from '@clearscore/validation.max-length';
import { minLengthValidation } from '@clearscore/validation.min-length';

const alphanumericRegex = /^[a-zA-Z0-9]*$/;

export const isAlphaNumericValidation = (errorMessage) => (value) => {
    if (typeof value !== 'string' || !alphanumericRegex.test(value)) return errorMessage;
    return undefined;
};

export const isMiddleNameValidation = (length, errorMessage) => (value) => {
    if (isEmpty(value)) {
        return undefined;
    }

    if (!maxLength(length)(value) || !isMultiSpecialCharName(value)) {
        return errorMessage;
    }

    return undefined;
};

export const isForeignPassportValidation = (errorMessage) => (value) => {
    if (/\s/g.test(value)) {
        return errorMessage;
    }

    return undefined;
};

export const isMedicareValidation = (errorMessage) => (value) => {
    if (/[0-9]{4}\s[0-9]{5}\s[0-9]{1}$/g.test(value) || /^[0-9]{10}$/g.test(value)) {
        return undefined;
    }

    return errorMessage;
};

const { NSW, VIC, ACT, NT, QLD, SA, TAS, WA } = AUSTRALIAN_STATES.reduce(
    (acc, { value }) => ({ ...acc, [value]: value }),
    {},
);

const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

const spacesRegex = /\s/;

export const isDrivingLicence =
    ({ requirements, spaces } = {}, auState) =>
    (value) => {
        if (spacesRegex.test(value)) return spaces;

        if (!alphanumericRegex.test(value)) return requirements;

        switch (auState) {
            case ACT:
                return composeValidators(
                    maxLengthValidation(requirements)(10),
                    isNumberValidation(requirements),
                )(value);
            case NT:
                return composeValidators(maxLengthValidation(requirements)(7), isNumberValidation(requirements))(value);
            case QLD:
                return composeValidators(
                    minLengthValidation(requirements)(8),
                    maxLengthValidation(requirements)(9),
                    isNumberValidation(requirements),
                )(value);
            case SA:
                return composeValidators(
                    minLengthValidation(requirements)(6),
                    maxLengthValidation(requirements)(6),
                )(value);
            case TAS:
                return composeValidators(
                    minLengthValidation(requirements)(6),
                    maxLengthValidation(requirements)(8),
                )(value);
            case VIC:
                return composeValidators(
                    maxLengthValidation(requirements)(10),
                    isNumberValidation(requirements),
                )(value);
            case WA:
                return composeValidators(
                    minLengthValidation(requirements)(7),
                    maxLengthValidation(requirements)(7),
                    isNumberValidation(requirements),
                )(value);
            case NSW:
            default:
                return composeValidators(
                    minLengthValidation(requirements)(6),
                    maxLengthValidation(requirements)(8),
                )(value);
        }
    };

export const isDrivingLicenceCardNumber =
    ({ requirements } = {}, state) =>
    (value) => {
        switch (state) {
            case ACT:
            case QLD:
                return composeValidators(
                    minLengthValidation(requirements)(10),
                    maxLengthValidation(requirements)(10),
                    isAlphaNumericValidation(requirements),
                )(value);
            case NT:
                return composeValidators(
                    minLengthValidation(requirements)(6),
                    maxLengthValidation(requirements)(8),
                    isNumberValidation(requirements),
                )(value);
            case SA:
            case TAS:
                return composeValidators(
                    minLengthValidation(requirements)(9),
                    maxLengthValidation(requirements)(9),
                    isAlphaNumericValidation(requirements),
                )(value);
            case VIC:
                return composeValidators(
                    minLengthValidation(requirements)(8),
                    maxLengthValidation(requirements)(8),
                    isAlphaNumericValidation(requirements),
                )(value);
            case WA:
                return composeValidators(
                    minLengthValidation(requirements)(8),
                    maxLengthValidation(requirements)(10),
                    isAlphaNumericValidation(requirements),
                )(value);
            case NSW:
            default:
                return composeValidators(
                    minLengthValidation(requirements)(10),
                    maxLengthValidation(requirements)(10),
                    isNumberValidation(requirements),
                )(value);
        }
    };

// VixVerify are making card number verification for different states mandatory at different times.
// Although all of the validation rules have been developed, VIC are set to be rolled out in 2023.
// In the meantime, the following function is used to conditionally toggle visibility based on
// state. This functionality can be removed when the final states are added.
export const isDrivingLicenceCardNumberRequired = (state) => [NT, TAS, WA, NSW, ACT, SA, QLD].includes(state);

// note:
// the value of these consts are used as the name for the fields
// these names _must_ stay as is for the BE API to work
export const FULLNAME = 'fullName';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const MIDDLE_NAME = 'middleNames';
export const GENDER = 'gender';
export const PAN = 'pan';
export const PHONE_NUMBER = 'phoneNumber';
export const PIN_CODE = 'pinCode';
export const PASSPORT = 'passport';
export const ID_TYPE = 'idType';
export const PASSWORD = 'password';
export const TERMS = 'terms';
export const PRIVACY = 'privacy';
export const TERMS_AND_PRIVACY = 'termsandprivacy';
export const LEGAL = 'legal';
export const SUBMIT = 'submit';
export const MALE = 'male';
export const FEMALE = 'female';
export const TRANSGENDER = 'transgender';
export const DOC_TYPE_NATIONAL_ID = 'NationalId';
export const DOC_TYPE_PASSPORT = 'Passport';
export const DOC_TYPE_PASSPORT_AU = 'passport';
export const DOC_TYPE_PASSPORT_AU_FOREIGN = 'passportAuForeign';
export const DOC_TYPE_PASSPORT_COUNTRY = 'passportCountry';
export const DOC_TYPE_PAN = 'PAN';
export const DOC_TYPE_VOTER_ID = 'VOTERID';
export const DOC_TYPE_DRIVING_LICENCE = 'DRIVING_LICENCE';
export const DOC_TYPE_DRIVING_LICENCE_STATE = 'stateOfIssue';
export const DOC_TYPE_DRIVING_LICENCE_AU = 'driversLicence';
export const DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER = 'licenceCardNumber';
export const DOC_TYPE_MEDICARE = 'medicare';
export const DOC_TYPE_MEDICARE_CARD_TYPE = 'medicareCardType';
export const DOC_TYPE_MEDICARE_POSITION = 'medicarePosition';
export const DOC_TYPE_MEDICARE_EXPIRY = 'medicareExpiry';
export const DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS = 'medicareExpiryWithDays';
export const DOC_TYPE_RATION_CARD = 'RATION_CARD';
export const DOC_TYPE_SIN = 'SIN';
export const DATE_OF_BIRTH = 'dob';
export const DATE_OF_BIRTH_CA = 'dobCa';
export const DOCUMENT_ID = 'documentId';
export const SOCIAL_INSURANCE_NUMBER = 'socialInsuranceNumber';
export const AU_CURRENT_ADDRESS = 'AU_CURRENT_ADDRESS';
export const AU_PREVIOUS_ADDRESS = 'AU_PREVIOUS_ADDRESS';
export const ADDRESS_LENGTH_INSUFFICIENT = 'ADDRESS_LENGTH_INSUFFICIENT';
export const ALL_DETAILS_ENDPOINT = '/cs-registration/v1/register/all-details';
export const ACCOUNT_ENDPOINT = '/cs-registration/v1/register/account';
export const AU_ACCOUNT_ENDPOINT = '/au-registration/v1/register/account';

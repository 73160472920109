import { AU } from '@clearscore/config.i18n';

import { AU_CURRENT_ADDRESS } from '../../../lib/fields/field-names';

export default ({ market, name }) => {
    switch (market) {
        case AU:
            return {
                suggestIndex: name === AU_CURRENT_ADDRESS ? 1 : 2,
            };
        default:
            return {
                suggestIndex: 1,
            };
    }
};

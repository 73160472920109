import i18next from 'i18next';
import { GB, ZA, AU, CA } from '@clearscore/config.i18n';
import { AUSTRALIAN_STATES } from '@clearscore/constants.market-details';
import isEmpty from '@clearscore/validation.is-empty';
import getEnv from '@clearscore/helpers.envs';
import splitName from '@clearscore/helpers.split-name';
import interpolateWithComponents from '@clearscore/helpers.interpolate-with-components';
import TextInput from '@clearscore/rainbow.text';
import isoCountriesFormatter from '@shared/iso-countries-formatter';
import { isDrivingLicenceCardNumberRequired } from '@shared/validation-au';

import {
    ID_TYPE,
    DOC_TYPE_NATIONAL_ID,
    DOCUMENT_ID,
    DATE_OF_BIRTH,
    ACCOUNT_ENDPOINT,
    AU_ACCOUNT_ENDPOINT,
    TERMS_AND_PRIVACY,
    FIRST_NAME,
    MIDDLE_NAME,
    LAST_NAME,
    PASSWORD,
    LEGAL,
    SUBMIT,
    FULLNAME,
    PRIVACY,
    TERMS,
    SOCIAL_INSURANCE_NUMBER,
    AU_CURRENT_ADDRESS,
    AU_PREVIOUS_ADDRESS,
    ADDRESS_LENGTH_INSUFFICIENT,
    DOC_TYPE_DRIVING_LICENCE_STATE,
    DOC_TYPE_DRIVING_LICENCE_AU,
    DOC_TYPE_MEDICARE,
    DOC_TYPE_MEDICARE_CARD_TYPE,
    DOC_TYPE_MEDICARE_POSITION,
    DOC_TYPE_MEDICARE_EXPIRY,
    DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS,
    DOC_TYPE_PASSPORT_COUNTRY,
    DOC_TYPE_PASSPORT_AU,
    DOC_TYPE_PASSPORT_AU_FOREIGN,
    DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER,
    DOC_TYPE_SIN,
} from './fields/field-names';
import getFieldProps from './fields';
import {
    SubmitButton,
    SegmentedGroup,
    DateInput,
    Password,
    Text,
    Legal,
    Checkbox,
    FormLayoutGroup,
    Dropdown,
    AddressAutosuggest,
} from '../components';
import { isGreenMedicareVisible, isBlueOrYellowMedicareVisible, getMedicareOptions } from './medicare-helpers';
import { extractDobFromDocumentID, forceToFourDigitYear, formatDateToIso } from './date-helpers';
import {
    DOC_TYPE_MEDICARE_CARD_TYPE_GREEN,
    DOC_TYPE_MEDICARE_CARD_TYPE_YELLOW,
    DOC_TYPE_MEDICARE_CARD_TYPE_BLUE,
    PAGE_NAMESPACE,
    auIso,
} from './constants';

export default ({ market = GB, locale, isoCountries, currentSuggestions, previousSuggestions, ...details }) => {
    const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
    const fieldProps = getFieldProps({ market, locale, details, currentSuggestions, previousSuggestions });
    switch (market) {
        case ZA:
            return {
                mainTitle: t('mainTitle'),
                subTitle: t('subTitle'),
                onSubmitEndpoint: ACCOUNT_ENDPOINT,
                onSubmitTransform: {
                    tncAgreedVersion: () => ({ tncAgreedVersion: 4 }),
                    dob: (values) => ({
                        dob:
                            values.idType === DOC_TYPE_NATIONAL_ID
                                ? extractDobFromDocumentID(values.documentId)
                                : forceToFourDigitYear(values.dob),
                    }),
                },
                initialValues: {
                    [ID_TYPE]: DOC_TYPE_NATIONAL_ID,
                    [TERMS_AND_PRIVACY]: true,
                },
                layoutComponents: [
                    () => Text({ ...fieldProps.firstNames, key: FIRST_NAME }),
                    () => Text({ ...fieldProps.lastName, key: LAST_NAME }),
                    (formProps) =>
                        Text({
                            ...fieldProps[formProps.values[ID_TYPE]],
                            forId: ID_TYPE,
                            key: fieldProps[formProps.values[ID_TYPE]],
                            name: DOCUMENT_ID,
                            maxLength: fieldProps[formProps.values[ID_TYPE]].maxLength,
                            validate: fieldProps[formProps.values[ID_TYPE]].validate,
                            parse: fieldProps[formProps.values[ID_TYPE]].parse,
                            format: fieldProps[formProps.values[ID_TYPE]].format,
                            isDockedTop: true,
                            hasFocus: formProps.modified[ID_TYPE],
                            description: fieldProps[formProps.values[ID_TYPE]].description,
                        }),
                    () => Password({ ...fieldProps.relaxedPassword, key: PASSWORD }),
                    () => Checkbox({ ...fieldProps.terms, key: TERMS, hasFormLayout: false }),
                    () => Checkbox({ ...fieldProps.privacy, key: PRIVACY, hasFormLayout: true }),
                    () => Legal({ ...fieldProps.legal, key: LEGAL }),
                    (formProps) => SubmitButton({ ...fieldProps.submit, key: SUBMIT, formProps }),
                ],
            };
        case AU: {
            const [AU_DRIVING_LICENCE_ENABLED, AU_PASSPORT_ENABLED, AU_MEDICARE_ENABLED] = getEnv([
                'AU_DRIVING_LICENCE_ENABLED',
                'AU_PASSPORT_ENABLED',
                'AU_MEDICARE_ENABLED',
            ]);
            const idTypes = [
                AU_DRIVING_LICENCE_ENABLED && DOC_TYPE_DRIVING_LICENCE_AU,
                AU_PASSPORT_ENABLED && DOC_TYPE_PASSPORT_AU,
                AU_MEDICARE_ENABLED && DOC_TYPE_MEDICARE,
            ].filter(Boolean);
            const initialIdType = idTypes[0];

            return {
                mainTitle: t('mainTitle'),
                subTitle: t('subTitle'),
                onSubmitEndpoint: AU_ACCOUNT_ENDPOINT,
                hasCountriesFetch: true,
                getKeysToRemove: (values) => [
                    DOC_TYPE_PASSPORT_AU_FOREIGN,
                    DOC_TYPE_PASSPORT_COUNTRY,
                    DOC_TYPE_DRIVING_LICENCE_STATE,
                    DOC_TYPE_MEDICARE_CARD_TYPE,
                    DOC_TYPE_MEDICARE_POSITION,
                    DOC_TYPE_MEDICARE_CARD_TYPE,
                    DOC_TYPE_MEDICARE_EXPIRY,
                    DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS,
                    ADDRESS_LENGTH_INSUFFICIENT,
                    AU_CURRENT_ADDRESS,
                    AU_PREVIOUS_ADDRESS,
                    ...idTypes.filter((type) => type !== values[ID_TYPE]),
                ],
                isBlackboxStart: true,
                onSubmitTransform: {
                    dob: (values) => ({ dob: formatDateToIso(values.dob) }),
                    driversLicence: (values) => {
                        const driversLicense = values[DOC_TYPE_DRIVING_LICENCE_AU];
                        if (!driversLicense) {
                            return {};
                        }

                        const stateOfIssue = values[DOC_TYPE_DRIVING_LICENCE_STATE];
                        const cardNumber = values[DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER];

                        return {
                            driversLicence: {
                                documentId: driversLicense,
                                stateOfIssue,
                                ...(isDrivingLicenceCardNumberRequired(stateOfIssue) && { cardNumber }),
                            },
                        };
                    },
                    passport: (values) =>
                        values[DOC_TYPE_PASSPORT_COUNTRY] && {
                            passport: {
                                documentId: values[DOC_TYPE_PASSPORT_AU] || values[DOC_TYPE_PASSPORT_AU_FOREIGN],
                                countryOfIssue: values[DOC_TYPE_PASSPORT_COUNTRY],
                            },
                        },
                    medicare: (values) =>
                        values[DOC_TYPE_MEDICARE] && {
                            medicare: {
                                cardColour: values[DOC_TYPE_MEDICARE_CARD_TYPE],
                                position: parseInt(values[DOC_TYPE_MEDICARE_POSITION], 10),
                                cardExpiry:
                                    values[DOC_TYPE_MEDICARE_CARD_TYPE] === DOC_TYPE_MEDICARE_CARD_TYPE_GREEN
                                        ? values[DOC_TYPE_MEDICARE_EXPIRY]
                                        : values[DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS],
                                documentId: values[DOC_TYPE_MEDICARE].replace(/ /g, ''),
                            },
                        },
                    addresses: (values) => {
                        const matchSuggestion = (suggestions, name) =>
                            suggestions.find((suggestion) => suggestion.formattedAddress === values[name]) || {};
                        const { structuredAddress: currentAddress } = matchSuggestion(
                            currentSuggestions,
                            AU_CURRENT_ADDRESS,
                        );
                        const { structuredAddress: previousAddress } = matchSuggestion(
                            previousSuggestions,
                            AU_PREVIOUS_ADDRESS,
                        );
                        const addressLengthInsufficient = values[ADDRESS_LENGTH_INSUFFICIENT];

                        return {
                            addressHistory: [...(addressLengthInsufficient ? [previousAddress] : []), currentAddress],
                        };
                    },
                },
                initialValues: {
                    [ID_TYPE]: initialIdType,
                },
                layoutComponents: [
                    () =>
                        FormLayoutGroup({
                            key: fieldProps[FULLNAME].key,
                            label: fieldProps[FULLNAME].label,
                            description: fieldProps[FULLNAME].description,
                            labelId: fieldProps[FIRST_NAME].forId,
                            children: [
                                Text({ ...fieldProps[FIRST_NAME], key: FIRST_NAME, showLabel: false }),
                                Text({ ...fieldProps[MIDDLE_NAME], key: MIDDLE_NAME, showLabel: false }),
                                Text({ ...fieldProps[LAST_NAME], key: LAST_NAME, showLabel: false }),
                            ],
                        }),
                    () => DateInput({ ...fieldProps.dateOfBirth, key: DATE_OF_BIRTH }),
                    // Address
                    (formProps) =>
                        AddressAutosuggest({
                            ...fieldProps[AU_CURRENT_ADDRESS],
                            options: currentSuggestions.filter(
                                ({ formattedAddress }) => formattedAddress !== formProps.values[AU_PREVIOUS_ADDRESS],
                            ),
                            market: AU,
                            requiredMessage: t(`${AU_CURRENT_ADDRESS}.requiredMessage`),
                        }),
                    () => Checkbox(fieldProps[ADDRESS_LENGTH_INSUFFICIENT]),
                    (formProps) =>
                        AddressAutosuggest({
                            ...fieldProps[AU_PREVIOUS_ADDRESS],
                            options: previousSuggestions.filter(
                                ({ formattedAddress }) => formattedAddress !== formProps.values[AU_CURRENT_ADDRESS],
                            ),
                            isHidden: !formProps.values[ADDRESS_LENGTH_INSUFFICIENT],
                            market: AU,
                            requiredMessage: t(`${AU_PREVIOUS_ADDRESS}.requiredMessage`),
                        }),
                    // ID_TYPE start
                    () =>
                        Dropdown({
                            ...fieldProps[ID_TYPE],
                            label: t(`${ID_TYPE}.label`),
                            labelDescription: t(`${ID_TYPE}.labelDescription`),
                            labelId: fieldProps[ID_TYPE].forId,
                            options: idTypes.map((type) => ({
                                value: type,
                                text: t(`${ID_TYPE}.options.${type}`),
                            })),
                        }),
                    // DRIVING LICENCE
                    (formProps) =>
                        Dropdown({
                            ...fieldProps[DOC_TYPE_DRIVING_LICENCE_STATE],
                            label: t(`${DOC_TYPE_DRIVING_LICENCE_STATE}.label`),
                            labelId: fieldProps[DOC_TYPE_DRIVING_LICENCE_STATE].forId,
                            isHidden: formProps.values[ID_TYPE] !== DOC_TYPE_DRIVING_LICENCE_AU,
                            options: AUSTRALIAN_STATES,
                        }),
                    (formProps) =>
                        Text({
                            ...fieldProps[DOC_TYPE_DRIVING_LICENCE_AU],
                            isHidden: formProps.values[ID_TYPE] !== DOC_TYPE_DRIVING_LICENCE_AU,
                        }),
                    (formProps) => {
                        const stateOfIssue = formProps.values[DOC_TYPE_DRIVING_LICENCE_STATE];
                        const description = t([
                            `${DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER}.descriptionOverrides.${stateOfIssue}`,
                            `${DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER}.description`,
                        ]);

                        return Text({
                            ...fieldProps[DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER],
                            description: interpolateWithComponents(description, [
                                {
                                    Component: TextInput,
                                    props: {
                                        weight: TextInput.weights.BOLD,
                                        tag: TextInput.tags.SPAN,
                                        type: TextInput.types.MINI,
                                    },
                                    key: DOC_TYPE_DRIVING_LICENCE_CARD_NUMBER,
                                },
                            ]),
                            isHidden:
                                formProps.values[ID_TYPE] !== DOC_TYPE_DRIVING_LICENCE_AU ||
                                !isDrivingLicenceCardNumberRequired(stateOfIssue),
                        });
                    },
                    // MEDICARE
                    (formProps) =>
                        SegmentedGroup({
                            ...fieldProps[DOC_TYPE_MEDICARE_CARD_TYPE],
                            initialActiveFieldName: DOC_TYPE_MEDICARE_CARD_TYPE_GREEN,
                            options: [
                                {
                                    text: t(
                                        `${DOC_TYPE_MEDICARE_CARD_TYPE}.options.${DOC_TYPE_MEDICARE_CARD_TYPE_GREEN}`,
                                    ),
                                    value: DOC_TYPE_MEDICARE_CARD_TYPE_GREEN,
                                },
                                {
                                    text: t(
                                        `${DOC_TYPE_MEDICARE_CARD_TYPE}.options.${DOC_TYPE_MEDICARE_CARD_TYPE_BLUE}`,
                                    ),
                                    value: DOC_TYPE_MEDICARE_CARD_TYPE_BLUE,
                                },
                                {
                                    text: t(
                                        `${DOC_TYPE_MEDICARE_CARD_TYPE}.options.${DOC_TYPE_MEDICARE_CARD_TYPE_YELLOW}`,
                                    ),
                                    value: DOC_TYPE_MEDICARE_CARD_TYPE_YELLOW,
                                },
                            ],
                            key: DOC_TYPE_MEDICARE_CARD_TYPE,
                            isHidden: formProps.values[ID_TYPE] !== DOC_TYPE_MEDICARE,
                            hasFormLayout: true,
                            onMount: () =>
                                formProps.form.mutators.setValue(
                                    DOC_TYPE_MEDICARE_CARD_TYPE,
                                    DOC_TYPE_MEDICARE_CARD_TYPE_GREEN,
                                ),
                        }),
                    (formProps) =>
                        Text({
                            ...fieldProps[DOC_TYPE_MEDICARE],
                            isHidden: formProps.values[ID_TYPE] !== DOC_TYPE_MEDICARE,
                        }),
                    (formProps) =>
                        Dropdown({
                            ...fieldProps[DOC_TYPE_MEDICARE_POSITION],
                            initialActiveFieldName: formProps.values[DOC_TYPE_MEDICARE_POSITION],
                            labelId: fieldProps[DOC_TYPE_MEDICARE_POSITION].forId,
                            options: getMedicareOptions({
                                initialOption: t(`${DOC_TYPE_MEDICARE_POSITION}.initialOption`),
                                noOfOptions: 6,
                            }),
                            isHidden: formProps.values[ID_TYPE] !== DOC_TYPE_MEDICARE,
                        }),
                    (formProps) =>
                        DateInput({
                            ...fieldProps[DOC_TYPE_MEDICARE_EXPIRY_WITH_DAYS],
                            isHidden: !isBlueOrYellowMedicareVisible(formProps),
                            maskFormat: 'DD/MM/YYYY',
                        }),

                    (formProps) =>
                        DateInput({
                            ...fieldProps[DOC_TYPE_MEDICARE_EXPIRY],
                            isHidden: !isGreenMedicareVisible(formProps),
                            maskFormat: 'MM/YYYY',
                        }),
                    // PASSPORT
                    (formProps) => {
                        const options = isoCountriesFormatter({ isoCountries, topCountry: auIso }) || [];
                        return Dropdown({
                            ...fieldProps[DOC_TYPE_PASSPORT_COUNTRY],
                            labelId: fieldProps[DOC_TYPE_PASSPORT_COUNTRY].forId,
                            isHidden: formProps.values[ID_TYPE] !== DOC_TYPE_PASSPORT_AU,
                            isDisabled: !options.length,
                            options,
                            onMount: () => formProps.form.mutators.setValue(DOC_TYPE_PASSPORT_COUNTRY, auIso),
                        });
                    },
                    (formProps) =>
                        Text({
                            ...fieldProps[DOC_TYPE_PASSPORT_AU_FOREIGN],
                            isHidden:
                                formProps.values[ID_TYPE] !== DOC_TYPE_PASSPORT_AU ||
                                (formProps.values[ID_TYPE] === DOC_TYPE_PASSPORT_AU &&
                                    formProps.values[DOC_TYPE_PASSPORT_COUNTRY] === auIso),
                        }),
                    (formProps) =>
                        Text({
                            ...fieldProps[DOC_TYPE_PASSPORT_AU],
                            isHidden:
                                formProps.values[ID_TYPE] !== DOC_TYPE_PASSPORT_AU ||
                                (formProps.values[ID_TYPE] === DOC_TYPE_PASSPORT_AU &&
                                    formProps.values[DOC_TYPE_PASSPORT_COUNTRY] !== auIso),
                        }),
                    // ID_TYPE end
                    () => Password({ ...fieldProps.relaxedPassword, key: PASSWORD }),
                    () => Legal({ ...fieldProps.legal, key: LEGAL }),
                    (formProps) => SubmitButton({ ...fieldProps[SUBMIT], key: SUBMIT, formProps }),
                ],
            };
        }

        case CA:
            return {
                getKeysToRemove: (values) => {
                    if (isEmpty(values[DOCUMENT_ID])) {
                        return [DOCUMENT_ID];
                    }
                    return [];
                },
                mainTitle: t('mainTitle'),
                subTitle: t('subTitle'),
                onSubmitEndpoint: ACCOUNT_ENDPOINT,
                onSubmitTransform: {
                    dob: (values) => ({ dob: values.dob.split('/').join('-') }),
                    driversLicence: (values) =>
                        values[DOC_TYPE_DRIVING_LICENCE_AU] && {
                            driversLicence: {
                                documentId: values[DOC_TYPE_DRIVING_LICENCE_AU],
                                stateOfIssue: values[DOC_TYPE_DRIVING_LICENCE_STATE],
                            },
                        },
                    tncAgreedVersion: () => ({ tncAgreedVersion: 1 }),
                },
                initialValues: {
                    [ID_TYPE]: DOC_TYPE_SIN,
                },
                layoutComponents: [
                    () =>
                        FormLayoutGroup({
                            key: fieldProps[FULLNAME].key,
                            label: fieldProps[FULLNAME].label,
                            description: fieldProps[FULLNAME].description,
                            labelId: fieldProps[FIRST_NAME].forId,
                            children: [
                                Text({ ...fieldProps[FIRST_NAME], key: FIRST_NAME, showLabel: false }),
                                Text({ ...fieldProps[LAST_NAME], key: LAST_NAME, showLabel: false }),
                            ],
                        }),
                    () => DateInput({ ...fieldProps.dateOfBirth, key: DATE_OF_BIRTH, maskFormat: 'YYYY/MM/DD' }),
                    () =>
                        Text({
                            ...fieldProps[SOCIAL_INSURANCE_NUMBER],
                            forId: SOCIAL_INSURANCE_NUMBER,
                            key: fieldProps[SOCIAL_INSURANCE_NUMBER].key,
                            name: DOCUMENT_ID,
                            label: t(`${SOCIAL_INSURANCE_NUMBER}.label`),
                            description: t(`${SOCIAL_INSURANCE_NUMBER}.description`),
                        }),
                    () => Password({ ...fieldProps.password, key: PASSWORD }),
                    () => Checkbox({ ...fieldProps.terms, key: TERMS, hasFormLayout: false }),
                    () => Legal({ ...fieldProps.legal, key: LEGAL }),
                    (formProps) => SubmitButton({ ...fieldProps.submit, key: SUBMIT, formProps }),
                ],
            };
        case GB:
        default:
            return {
                mainTitle: t('mainTitle'),
                subTitle: t('subTitle'),
                onSubmitEndpoint: ACCOUNT_ENDPOINT,
                onSubmitTransform: {
                    dob: (values) => ({ dob: forceToFourDigitYear(values.dob) }),
                    firstName: (values) => ({ firstName: splitName(values.fullName).firstName }),
                    middleNames: (values) => ({ middleNames: splitName(values.fullName).middleNames }),
                    lastName: (values) => ({ lastName: splitName(values.fullName).lastName }),
                    tncAgreedVersion: () => ({ tncAgreedVersion: 8 }),
                },
                initialValues: {},
                layoutComponents: [
                    () => Text({ ...fieldProps.fullName, key: FULLNAME }),
                    () => DateInput({ ...fieldProps.dateOfBirth, key: DATE_OF_BIRTH }),
                    () => Password({ ...fieldProps.password, key: PASSWORD }),
                    () => Checkbox({ ...fieldProps.terms, key: TERMS, hasFormLayout: false }),
                    () => Checkbox({ ...fieldProps.privacy, key: PRIVACY, hasFormLayout: true }),
                    () => Legal({ ...fieldProps.legal, key: LEGAL }),
                    (formProps) => SubmitButton({ ...fieldProps.submit, key: SUBMIT, formProps }),
                ],
            };
    }
};

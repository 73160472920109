import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Label from '@clearscore/rainbow.label';
import DropdownComponent from '@clearscore/rainbow.dropdown';

const Dropdown = ({
    name,
    label,
    labelId,
    labelDescription,
    validate,
    isHidden,
    isDisabled,
    onMount,
    ...componentProps
}) => {
    useEffect(() => {
        if (!isHidden && onMount) {
            onMount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHidden]);

    if (isHidden) return null;

    return (
        <div key={name} data-qa="dropdown" data-id={name}>
            {label ? (
                <Label dataId={`${labelId}-label`} htmlFor={labelId} description={labelDescription}>
                    {label}
                </Label>
            ) : null}

            <Field name={name} validate={validate} type="select">
                {({ input, meta }) => {
                    const onChange = componentProps.onChange ? componentProps.onChange : input.onChange;

                    return (
                        <DropdownComponent
                            {...input}
                            isLightBg
                            name={name}
                            type="select"
                            options={componentProps.options}
                            placeholder={componentProps.placeholder}
                            autoComplete={componentProps.autocomplete}
                            prepend={componentProps.prepend}
                            suffix={componentProps.suffix}
                            value={input.value}
                            isDirty={meta.dirty || meta.touched}
                            isDisabled={isDisabled || meta.disabled}
                            isInvalid={(meta.dirty || meta.touched) && meta.error && !!validate}
                            isValid={(meta.dirty || meta.touched) && !meta.error && meta.modified && !!validate}
                            errorMessage={meta.dirty || meta.touched ? meta.error : ''}
                            onChange={onChange}
                        />
                    );
                }}
            </Field>
        </div>
    );
};

Dropdown.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    validate: PropTypes.func,
    onChange: PropTypes.func,
    labelId: PropTypes.string,
    isHidden: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onMount: PropTypes.func,
};

Dropdown.defaultProps = {
    validate: null,
    onChange: null,
    labelId: undefined,
    labelDescription: undefined,
    isHidden: false,
    isDisabled: false,
    onMount: null,
};

export default Dropdown;

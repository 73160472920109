import { isRequiredValidation } from '@clearscore/validation.is-required';
import { isEmailValidation } from '@clearscore/validation.is-email';

const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

const isSameEmail = (errorMessage, email) => (value) => value === email ? errorMessage : undefined;

export default ({ language, email }) =>
    composeValidators(
        isRequiredValidation(language.isRequiredError),
        isEmailValidation(language.isEmailError),
        isSameEmail(language.isSameEmailError, email),
    );

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import TextInput from '@clearscore/rainbow.text-input';
import Label from '@clearscore/rainbow.label';

import styles from './field.module.css';

const FieldComponent = ({
    Component,
    type,
    isHidden,
    name,
    validate,
    parse,
    showLabel,
    isDockedTop,
    hasFocus,
    requiredMessage,
    ...componentProps
}) => {
    let inputRef;
    useEffect(() => {
        if (hasFocus) inputRef.focus();
    }, [hasFocus, inputRef]);

    if (isHidden) return null;

    const conditionalProps = {
        ...(requiredMessage && {
            requiredMessage,
        }),
    };

    return (
        <div key={name} className={styles.fieldContainer}>
            {showLabel ? (
                <Label
                    dataId={`${componentProps.forId}-label`}
                    htmlFor={componentProps.forId}
                    description={componentProps.description}
                >
                    {componentProps.label}
                </Label>
            ) : null}
            <div className={styles.spacing}>
                <Field key={name} name={name} validate={validate} parse={parse}>
                    {({ input: { onChange, onBlur, onFocus, value }, meta: { dirty, error, touched } }) => (
                        <Component
                            isLightBg
                            id={componentProps.forId}
                            key={name}
                            type={type}
                            name={name}
                            prepend={componentProps.prepend}
                            placeholder={componentProps.placeholder}
                            maxLength={componentProps.maxLength}
                            onChange={onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            value={value}
                            isDirty={dirty}
                            isValid={!!value}
                            isInvalid={dirty && !!error}
                            errorMessage={dirty || touched ? error : ''}
                            autoComplete={componentProps.autocomplete}
                            data-qa={`${name}-input`}
                            requirements={componentProps.requirements}
                            isDockedTop={isDockedTop}
                            elementRef={(ref) => {
                                inputRef = ref;
                            }}
                            options={componentProps.options}
                            market={componentProps.market}
                            {...conditionalProps}
                        />
                    )}
                </Field>
            </div>
        </div>
    );
};

FieldComponent.propTypes = {
    Component: PropTypes.func,
    type: PropTypes.string,
    isHidden: PropTypes.bool,
    validate: PropTypes.func,
    parse: PropTypes.func,
    name: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    isDockedTop: PropTypes.bool,
    hasFocus: PropTypes.bool,
    requiredMessage: PropTypes.string,
};

FieldComponent.defaultProps = {
    Component: TextInput,
    type: 'text',
    isHidden: false,
    validate: null,
    parse: undefined,
    showLabel: true,
    isDockedTop: false,
    hasFocus: false,
    requiredMessage: null,
};

export default FieldComponent;

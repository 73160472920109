import React from 'react';
import PropTypes from 'prop-types';
import Button from '@clearscore/rainbow.button';
import Criteria from '@clearscore/rainbow.criteria';

import styles from './styles.module.css';
import fieldPropsShape from '../../lib/fields/field-props-shape';

const getFirstErrorMessage = (form) => {
    const formErrors = form.getState().errors;
    const firstFieldWithError = form.getRegisteredFields().find((field) => formErrors[field]);

    if (firstFieldWithError) {
        return formErrors[firstFieldWithError];
    }
    return '';
};

const SubmitButton = ({ label, name, formProps }) => (
    <div key={name} className={styles.submitButton}>
        <div className={styles.button}>
            <Button
                size={Button.sizes.LARGE}
                dataId="create-account-button"
                isLoading={formProps.submitting}
                isDisabled={formProps.pristine || formProps.invalid || formProps.submitting}
                htmlType="submit"
                isNarrow
                isResponsive
            >
                {label}
            </Button>
        </div>

        <div className={styles.errorMessage}>
            <Criteria dataId="ctaError" displayState={Criteria.DisplayState.WARNING}>
                {getFirstErrorMessage(formProps.form)}
            </Criteria>
        </div>
    </div>
);

SubmitButton.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    formProps: fieldPropsShape,
};

SubmitButton.defaultProps = {
    formProps: {},
};

export default SubmitButton;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FormLayout from '@clearscore/rainbow.form-layout';
import Label from '@clearscore/rainbow.label';

const FormLayoutGroup = ({ children, label, labelId, description }) => (
    <Fragment>
        {label ? (
            <Label dataId={`${labelId}-label`} htmlFor={labelId} description={description}>
                {label}
            </Label>
        ) : null}
        <FormLayout.Group isCondensed>{children.map((child) => child)}</FormLayout.Group>
    </Fragment>
);

FormLayoutGroup.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    label: PropTypes.string,
    labelId: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

FormLayoutGroup.defaultProps = {
    label: undefined,
    labelId: undefined,
    description: undefined,
};

export default FormLayoutGroup;

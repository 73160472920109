import React from 'react';
import PropTypes from 'prop-types';
import { MARKETS } from '@clearscore/config.i18n';
import Text from '@clearscore/rainbow.text';
import Container from '@clearscore/rainbow.container';
import Overlay from '@clearscore/rainbow.overlay';

import CreateAccountForm from './components/create-account-form';
import useIdentifyOnAccountCreate from './lib/hooks/use-identify-on-account-create';
import styles from './create-account.module.css';

const CreateAccount = ({
    market,
    onSubmitEndpoint,
    onSubmitTransform,
    onSubmitProps,
    onSubmit,
    mainTitle,
    subTitle,
    layoutComponents = [],
    useFetch,
    initialValues,
    isSubmitting,
    isEmailValid,
    getKeysToRemove,
    isBlackboxStart,
}) => {
    useIdentifyOnAccountCreate(market);

    useFetch();
    return (
        <Overlay>
            <Container
                size={Container.Size.MEDIUM}
                top={Container.Spacing.BIG}
                bottom={Container.Spacing.BIG}
                theme={Container.Theme.LIGHT}
                isFullHeight
            >
                {mainTitle ? (
                    <div className={styles.mainTitle}>
                        <Text.H2>{mainTitle}</Text.H2>
                    </div>
                ) : null}

                {subTitle ? (
                    <div className={styles.subTitle}>
                        <Text.Body1>{subTitle}</Text.Body1>
                    </div>
                ) : null}
                <CreateAccountForm
                    onSubmitProps={onSubmitProps}
                    onSubmit={onSubmit}
                    onSubmitEndpoint={onSubmitEndpoint}
                    onSubmitTransform={onSubmitTransform}
                    initialValues={initialValues}
                    isSubmitting={isSubmitting}
                    isEmailValid={isEmailValid}
                    getKeysToRemove={getKeysToRemove}
                    isBlackboxStart={isBlackboxStart}
                    layoutComponents={layoutComponents}
                    market={market}
                />
            </Container>
        </Overlay>
    );
};

CreateAccount.propTypes = {
    market: PropTypes.oneOf(MARKETS).isRequired,
    mainTitle: PropTypes.string,
    subTitle: PropTypes.string,
    onSubmitProps: PropTypes.object,
    onSubmitEndpoint: PropTypes.string.isRequired,
    onSubmitTransform: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    layoutComponents: PropTypes.arrayOf(PropTypes.func).isRequired,
    initialValues: PropTypes.object,
    useFetch: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isEmailValid: PropTypes.bool,
    getKeysToRemove: PropTypes.func,
    isBlackboxStart: PropTypes.bool,
};

CreateAccount.defaultProps = {
    mainTitle: null,
    subTitle: null,
    onSubmitProps: {},
    onSubmitTransform: {},
    initialValues: null,
    isEmailValid: true,
    getKeysToRemove: () => [],
    isBlackboxStart: false,
};

export default CreateAccount;

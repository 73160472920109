import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import DateInput from '@clearscore/rainbow.date-input';
import Label from '@clearscore/rainbow.label';

import styles from './index.module.css';

const DateInputComponent = ({ type, name, maskFormat, forId, validate, isHidden, label, description }) => {
    if (isHidden) return null;

    return (
        <div key={name} className={styles.fieldContainer}>
            {label ? (
                <Label dataId={`${forId}-label`} htmlFor={forId} description={description}>
                    {label}
                </Label>
            ) : null}
            <div className={styles.spacing}>
                <Field key={name} name={name} validate={validate}>
                    {({ input: { onChange, onBlur, onFocus, value }, meta: { dirty, error, touched } }) => (
                        <DateInput
                            isLightBg
                            id={forId}
                            maskFormat={maskFormat}
                            key={name}
                            type={type}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            value={value}
                            isDirty={dirty}
                            isValid={!!value}
                            isInvalid={dirty && !!error}
                            errorMessage={dirty || touched ? error : ''}
                            data-qa={`${name}-input`}
                            inputMode="numeric"
                            pattern="[0-9\/]+"
                        />
                    )}
                </Field>
            </div>
        </div>
    );
};

DateInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    forId: PropTypes.string.isRequired,
    maskFormat: PropTypes.string,
    type: PropTypes.string,
    validate: PropTypes.func,
    isHidden: PropTypes.bool,
    label: PropTypes.string,
    description: PropTypes.string,
};

DateInputComponent.defaultProps = {
    maskFormat: 'DD/MM/YYYY',
    type: 'text',
    validate: null,
    isHidden: false,
    label: null,
    description: null,
};

export default DateInputComponent;

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Spacer from '@clearscore/rainbow.spacer';
import TextInput from '@clearscore/rainbow.text-input';
import Button from '@clearscore/rainbow.button';
import Text from '@clearscore/rainbow.text';
import emailNormalize from '@clearscore/normalize.email';
import { selectors as registrationSelectors } from '@clearscore/redux.registration';
import { selectors as prospectSelectors, actions as prospectActions } from '@clearscore/redux.prospect';
import { getAnonymousId } from '@clearscore/helpers.segment';
import Label from '@clearscore/rainbow.label';

import { PAGE_NAMESPACE } from '../../lib/constants';
import validators from './lib/validators';
import styles from './styles.module.css';

const ChangeEmailForm = ({ market }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const dispatch = useDispatch();
    const { isPending: registrationPending } = useSelector(
        (state) => registrationSelectors.getPredicate(state).account,
    );
    const { isPending: createProspectPending } = useSelector((state) => prospectSelectors.getPredicate(state).fetch);
    const { email: validationEmail } = useSelector(prospectSelectors.getSimple);
    const emailValidation = validators({ language: t('changeEmailForm'), email: validationEmail });

    const handleOnSubmit = ({ email }) => {
        const anonymousId = getAnonymousId();
        dispatch(prospectActions.createProspect({ email, anonymousId }, market));
    };

    return (
        <Fragment>
            <div className={styles.white}>
                <Text.H2>{t('changeEmailForm.title')}</Text.H2>
                <Text type={Text.types.MINI}>{t('changeEmailForm.information')}</Text>
            </div>
            <Form
                onSubmit={handleOnSubmit}
                render={({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit} name="change-email-form">
                        <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.HUGE }}>
                            <Label htmlFor="email" theme={Label.Theme.DARK}>
                                {t('changeEmailForm.email.label')}
                            </Label>
                            <div className={styles.white}>
                                <Field name="email" normalize={emailNormalize} validate={emailValidation}>
                                    {({ input, meta }) => (
                                        <TextInput
                                            isLightBg
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            theme={TextInput.Theme.DARK}
                                            value={input.value}
                                            onBlur={input.onBlur}
                                            onChange={input.onChange}
                                            isValid={meta.touched && meta.valid}
                                            isInvalid={meta.touched && meta.invalid}
                                            errorMessage={(meta.touched && meta.error) || ''}
                                        />
                                    )}
                                </Field>
                            </div>
                        </Spacer>

                        <Button
                            htmlType="submit"
                            type={Button.types.PRIMARY}
                            size={Button.sizes.LARGE}
                            isLoading={registrationPending || createProspectPending}
                            isDisabled={invalid}
                            theme={Button.themes.DARK}
                            dataId="change-email-form-submit"
                        >
                            {t('changeEmailForm.submit')}
                        </Button>
                    </form>
                )}
            />
        </Fragment>
    );
};

ChangeEmailForm.propTypes = {
    market: PropTypes.string.isRequired,
};

export default ChangeEmailForm;

import i18n from 'i18next';
import { flatMap } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { setSessionCookie } from '@clearscore/helpers.cookies';
import { types } from '@clearscore/redux.registration';
import { actions as sessionActions } from '@clearscore/redux.session';
import { actions as marketActions } from '@clearscore/redux.market';
import getEnv from '@clearscore/helpers.envs';

/**
 * @description Middleware to handle create account registration
 * @param {Observable<Action>} action$ Observable
 * @param state$ Observable
 * @returns {Observable<Action>} Observable
 */
const processSignupEpic = (action$) =>
    action$.pipe(
        ofType(types.REGISTRATION_CREATE_ACCOUNT_SUCCESS),
        flatMap(({ payload, requestMeta: { market } }) => {
            // TODO update this when the backend returns the preferred locale CS-92028
            const [language] = i18n.language.split('-');
            const validLocale = i18n.languages.find((lang) => lang === `${language}-${market}`);
            const locale = validLocale || `en-${market}`;

            const hasOauth = getEnv('OAUTH_ENABLED');
            const session = hasOauth
                ? {
                      access_token: payload.accessToken,
                      refresh_token: payload.refreshToken,
                      refreshToken: payload.refreshToken,
                      market,
                      locale,
                  }
                : {
                      accountId: payload.accountId,
                      sessionToken: payload.sessionToken,
                      market,
                      locale,
                  };

            setSessionCookie(session);

            return concat(
                of(sessionActions.setNativeSession(session)),
                of(marketActions.setActiveLocale(locale)),
                of(marketActions.setActiveMarket(market)),
            );
        }),
    );

export default processSignupEpic;

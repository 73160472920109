import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useTracking from '@clearscore/hooks.use-tracking';
import { PROFILE_STATUSES } from '@clearscore/constants.profile-statuses';

/**
 * Run an analytics `identify` call when the prospect is created, so subsequent tracking
 * events are sent with the correct `userId` property
 *
 * @param {market} string the active market for the registration
 */
const useIdentifyOnAccountCreate = (market) => {
    const track = useTracking();
    const prospectId = useSelector((state) => state.prospect?.prospectId);
    const accountIDVStatus = useSelector((state) => state.registration?.accountIDVStatus) ?? '';

    useEffect(() => {
        if (prospectId) {
            track({
                name: prospectId,
                event: 'identify',
                props: {
                    // Override market (set to "global" in vertical's redux store during reg)
                    cs_site: market,
                },
            });

            const isValidIDV = [PROFILE_STATUSES.PASS, PROFILE_STATUSES.NO_FILE].includes(accountIDVStatus);

            if (isValidIDV) {
                track({
                    name: 'fe_first_time_login',
                    props: {
                        // Override market (set to "global" in vertical's redux store during reg)
                        cs_site: market,
                    },
                });
            }
        }
    }, [prospectId, accountIDVStatus]);
};

export default useIdentifyOnAccountCreate;

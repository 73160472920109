import format from 'date-fns/format';
import isEmpty from '@clearscore/validation.is-empty';
import { extrapolateYear, dateDiffInDaysMonthsYears } from '@clearscore/helpers.date';

const DEFAULT_MESSAGE = 'Valid date format required';
const INVALID_CHARACTER = "This doesn't seem to be a valid date of birth";
const DEFAULT_MESSAGE_MIN_AGE = 'Minimum age not met';

const inputtedDateRegexCA =
    // eslint-disable-next-line max-len, no-useless-escape
    /^\d{4}[\-\/\s]?((((0[13578])|(1[02]))[\-\/\s]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\-\/\s]?(([0-2][0-9])|(30)))|(02[\-\/\s]?[0-2][0-9]))$/;

function isValidDateTime(date) {
    const d = new Date(date);

    const [yearStr, monthStr, dayStr] = date.split('/');
    const month = parseInt(monthStr, 10) - 1;
    const day = parseInt(dayStr, 10);
    const year = parseInt(yearStr, 10);

    return d.getFullYear() === year && d.getMonth() === month && d.getDate() === day;
}

function minAge(minLegal) {
    return function minAgeValidator(value) {
        if (isEmpty(value) || !isValidDateTime(value)) {
            return false;
        }

        const age = dateDiffInDaysMonthsYears(new Date(value), new Date());
        return age.years >= minLegal;
    };
}

export const buildDate = (day, month, year) => `${day}/${month}/${year}`;

export const extractDobFromDocumentID = (documentId) => {
    const day = documentId.substring(4, 6);
    const month = documentId.substring(2, 4);
    const year = extrapolateYear(documentId.substring(0, 2));
    return buildDate(day, month, year);
};

export const forceToFourDigitYear = (date) => {
    if (date.match(/^\d{2}(-|\.|\/)\d{2}(-|\.|\/)\d{2}$/)) {
        const day = date.substring(0, 2);
        const month = date.substring(3, 5);
        const year = extrapolateYear(date.slice(-2));
        return buildDate(day, month, year);
    }

    return date;
};

export const formatDateToIso = (dob) => format(new Date(dob.split('/').reverse().join('-')), 'yyyy-MM-dd');

export function isCADate(validation) {
    return function getError(value) {
        if (isEmpty(value) || !isValidDateTime(value)) {
            return validation || DEFAULT_MESSAGE;
        }

        if (!value.trim().match(inputtedDateRegexCA)) {
            return validation || INVALID_CHARACTER;
        }

        return undefined;
    };
}

export function minAgeCAValidation() {
    return function minMax(min) {
        return function error(value) {
            return !minAge(min)(value) ? DEFAULT_MESSAGE_MIN_AGE : undefined;
        };
    };
}

export function isSIN(validation) {
    return function getError(value) {
        if (isEmpty(value)) {
            return undefined;
        }
        if (value.length > 0 && value.length !== 9) {
            return validation;
        }

        return undefined;
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Checkbox from '@clearscore/rainbow.checkbox';

import styles from './index.module.css';

const CheckboxInput = ({ label, name, validate }) => (
    <div data-qa="checkbox-input-wrapper" key={name} className={styles.spacing}>
        <Field key={name} name={name} validate={validate} type="checkbox">
            {({ input: { onChange, checked } }) => (
                <Checkbox
                    onChange={onChange}
                    dataId={`${name}-checkbox`}
                    size={Checkbox.Size.COMPACT}
                    checked={checked}
                    name={name}
                >
                    {label}
                </Checkbox>
            )}
        </Field>
    </div>
);

CheckboxInput.propTypes = {
    label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
};

CheckboxInput.defaultProps = {
    validate: null,
};

export default CheckboxInput;

import { ID_TYPE, DOC_TYPE_MEDICARE, DOC_TYPE_MEDICARE_CARD_TYPE } from '../fields/field-names';
import {
    DOC_TYPE_MEDICARE_CARD_TYPE_GREEN,
    DOC_TYPE_MEDICARE_CARD_TYPE_YELLOW,
    DOC_TYPE_MEDICARE_CARD_TYPE_BLUE,
} from '../constants';

export const isGreenMedicareVisible = (formProps) => {
    const isDocTypeMedicare = formProps.values[ID_TYPE] === DOC_TYPE_MEDICARE;
    const cardTypeValue = formProps.values[DOC_TYPE_MEDICARE_CARD_TYPE];
    if (isDocTypeMedicare && !cardTypeValue) return true;
    if (isDocTypeMedicare && cardTypeValue === DOC_TYPE_MEDICARE_CARD_TYPE_GREEN) return true;
    return false;
};

export const isBlueOrYellowMedicareVisible = (formProps) => {
    const isDocTypeMedicare = formProps.values[ID_TYPE] === DOC_TYPE_MEDICARE;
    const cardTypeValue = formProps.values[DOC_TYPE_MEDICARE_CARD_TYPE];
    if (
        isDocTypeMedicare &&
        (cardTypeValue === DOC_TYPE_MEDICARE_CARD_TYPE_BLUE || cardTypeValue === DOC_TYPE_MEDICARE_CARD_TYPE_YELLOW)
    )
        return true;
    return false;
};

export const getMedicareOptions = ({ initialOption, noOfOptions }) => [
    {
        text: initialOption,
        value: '',
    },
    ...[...Array(noOfOptions)].map((_, index) => ({
        text: `${index + 1}`,
        value: index + 1,
    })),
];

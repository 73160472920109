import connect from '@clearscore/redux.connect';
import withVertical from '@clearscore-group/ui.external-cs-auth.vertical';
import {
    selectors as marketAttributesSelectors,
    actions as marketAttributesActions,
} from '@clearscore/redux.market-attributes';
import { selectors as registrationSelectors } from '@clearscore/redux.registration';
import { selectors as prospectSelectors, actions as prospectActions } from '@clearscore/redux.prospect';
import { selectors as routerSelectors } from '@clearscore/redux.router';
import useRequest from '@clearscore/hooks.use-request';
import memoize from 'lodash.memoize';
import { getBlackbox } from '@shared/iovation-helpers';

import CreateAccount from './create-account';
import reducer from './lib/reducer';
import * as actions from './redux/actions';
import epics from './lib/epics';
import appBridgeTypes from './lib/appBridgeTypes';
import getMarketConfig from './lib/market-config';
import transformData from './lib/transform-data';

/**
 * If oauth device details are within querystring then return
 */
const deviceOAuthDetails = memoize((search = '') => {
    const urlParams = new URLSearchParams(search);
    const deviceId = urlParams.get('device_id');
    const deviceName = urlParams.get('device_name');
    const clientId = urlParams.get('client_id');
    const sessionId = urlParams.get('session_id');
    if (deviceId && clientId && sessionId && deviceName) {
        return {
            deviceId,
            clientId,
            sessionId,
            deviceName,
        };
    }
    return {};
});

const mapState = (state, { routeProps, match }) => {
    const { market } = match.params;
    const locale = `en-${market}`;
    const isoCountries = marketAttributesSelectors.getPassportIssuingIsoCountries(state);
    const { addresses: currentSuggestions } = registrationSelectors.getMultipleAddresses(state, 1);
    const { addresses: previousSuggestions } = registrationSelectors.getMultipleAddresses(state, 2);
    const marketConfig = getMarketConfig({
        market,
        locale,
        routeProps,
        isoCountries,
        currentSuggestions,
        previousSuggestions,
    });
    const { email, validEmail } = prospectSelectors.getSimple(state);

    const prospectId = routerSelectors.getRegistrationProspectId(state);
    return {
        ...marketConfig,
        market,
        onSubmitProps: {
            email,
            prospectId,
        },
        isEmailValid: validEmail,
        isSubmitting: registrationSelectors.getPredicate(state).account.isPending,
    };
};

const mapDispatch = (dispatch, { match }) => ({
    onSubmit: ({
        onSubmitTransform = {},
        onSubmitEndpoint,
        search,
        getKeysToRemove = () => [],
        isBlackboxStart,
        ...profileData
    }) => {
        const values = {
            ...profileData,
            ...transformData(profileData, onSubmitTransform),
        };
        const keysToRemove = getKeysToRemove(values);
        const profileDataWithKeysRemoved = Object.entries(values).reduce(
            (acc, [key, value]) => ({
                ...acc,
                ...(keysToRemove.includes(key) ? {} : { [key]: value }),
            }),
            {},
        );
        return dispatch(
            actions.createAccount(
                {
                    endpoint: onSubmitEndpoint,
                    accountData: {
                        ...(isBlackboxStart && { beginBlackbox: getBlackbox() }),
                        ...profileDataWithKeysRemoved,
                        ...deviceOAuthDetails(search),
                    },
                },
                match.params.market,
            ),
        );
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    useFetch: () => {
        const { market } = ownProps.match.params;
        useRequest([
            {
                selector: (state) => prospectSelectors.getPredicate(state).fetch,
                action: prospectActions.getProspect(stateProps.onSubmitProps.prospectId, market),
            },
            {
                selector: (state) =>
                    marketAttributesSelectors.getPredicate(state).fetchPassportIssuingIsoCountriesStatus,
                action: marketAttributesActions.fetchPassportIssuingIsoCountries(market),
                when: !!stateProps.hasCountriesFetch,
            },
        ]);
    },
});

const ConnectedComponent = connect(mapState, mapDispatch, { mergeProps, withRouter: true })(CreateAccount);

export default withVertical(ConnectedComponent, {
    appBridgeTypes,
    epics,
    name: 'create-account',
    reducer,
});

import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/rainbow.text';

import styles from './styles.module.css';

export const Legal = ({ introMessages }) => (
    <div key="legal-text-header" className={styles.introText}>
        <Text.Caption dataId="legal-text">{introMessages}</Text.Caption>
    </div>
);

Legal.propTypes = {
    introMessages: PropTypes.node.isRequired,
};

export default Legal;

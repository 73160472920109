import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Password from '@clearscore/rainbow.password';

const PasswordInput = ({ name, autoComplete, requirements, placeholder, id }) => (
    <Field key={name} name={name}>
        {({ input: { onChange, onBlur, onFocus, value }, meta: { dirty, error, touched } }) => (
            <Password
                id={id}
                key={name}
                name={name}
                isLightBg
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                value={value}
                isDirty={dirty}
                isValid={!!value}
                isInvalid={dirty && !!error}
                errorMessage={dirty || touched ? error : ''}
                autoComplete={autoComplete}
                data-qa={`${name}-input`}
                requirements={requirements}
            />
        )}
    </Field>
);

PasswordInput.propTypes = {
    name: PropTypes.string.isRequired,
    requirements: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    autoComplete: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
};

export default PasswordInput;

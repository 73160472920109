import { types as marketAttributesTypes } from '@clearscore/redux.market-attributes';
import { types as registrationTypes } from '@clearscore/redux.registration';
import { types as prospectTypes } from '@clearscore/redux.prospect';
import { constants } from '@clearscore/redux.alerts';
import Text from '@clearscore/rainbow.text';
import i18next from 'i18next';

import { PAGE_NAMESPACE } from './constants';

const alertWithMessage = (message, alertType = constants.ERROR) => ({
    message,
    alertType,
    isDismissible: true,
});

const alertWithComponents = (message, alertType = constants.ERROR) => ({
    message,
    alertType,
    isDismissible: true,
    Components: [
        {
            Component: Text.Link,
            props: {
                isNewTab: true,
                href: 'https://clearscore.com/askaquestion',

                key: 'link',
            },
        },
    ],
});

const errorContainsPincode = (errors) => errors.some(({ field }) => field === 'pincode');

export default {
    [registrationTypes.REGISTRATION_CREATE_ACCOUNT_ERROR]: ({ locale, payload: { data = {}, status } }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        switch (status) {
            case 409:
                return alertWithComponents(t('createAccount409'));
            case 400:
                if (data && data.errors && errorContainsPincode(data.errors)) {
                    return alertWithMessage(t('createAccountPincodeError'));
                }

                return alertWithMessage(t('createAccountError'));
            default:
                return alertWithMessage(t('createAccountError'));
        }
    },
    [prospectTypes.PROSPECT_GET_ERROR]: ({ locale }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        return alertWithMessage(t('genericError'));
    },
    [prospectTypes.PROSPECT_CREATE_ERROR]: ({ locale }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        return alertWithMessage(t('genericError'));
    },
    [prospectTypes.PROSPECT_CREATE_SUCCESS]: ({ locale, payload = {} }) => {
        if (payload.validEmail) return null;
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        return alertWithMessage(t('invalidEmailWarning'), constants.WARNING);
    },
    [marketAttributesTypes.MARKET_ATTRIBUTES_PASSPORT_ISSUING_ISO_COUNTRIES_FETCH_ERROR]: ({ locale }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        return alertWithMessage(t('genericError'));
    },
    [registrationTypes.REGISTRATION_FETCH_MULTIPLE_ADDRESSES_ERROR]: ({ locale }) => {
        const t = i18next.getFixedT(locale, PAGE_NAMESPACE);
        return alertWithMessage(t('genericError'));
    },
};

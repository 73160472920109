export default ({ isoCountries, topCountry } = {}) => {
    if (!isoCountries || !topCountry || (Array.isArray(isoCountries) && !isoCountries.length)) return null;

    const topCountryOption = isoCountries.find(({ key }) => key === topCountry);

    if (!topCountryOption) return null;

    return [topCountryOption, ...isoCountries.filter(({ key }) => key !== topCountry)].map(({ key, value }) => ({
        value: key,
        text: value,
    }));
};

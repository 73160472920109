import { alertsEpic } from '@clearscore/epic.alerts';

import processSignupEpic from './epics/process-signup.epic';
import createAccountEpic from './epics/create-account.epic';
import alertConfig from './alerts-config';

export default {
    processSignupEpic,
    createAccountEpic,
    alerts: alertsEpic(alertConfig),
};
